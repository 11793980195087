// src/config.js
export const npcs = [
  {
    name: '裁决之刃',
    description: '这是一个普通的交易NPC，主要职责是提供各种交易服务，可能出售一些基础的物品和资源.',
    dropItems: '夜焰等离子、影翼膜',
    exchange_items: '钻石、彩虹晶体、绿宝石、夜焰等离子、等离子裁决套装'
  },
  {
    name: '裁决之刃-长官',
    description: '这是裁决之刃的高级版本，拥有更多的交易选项，可能会提供一些稀有物品和高级资源。此外，长官级NPC通常还承担着更多的管理和指导职责.',
    dropItems: '夜焰等离子、影翼膜、等离子裁决套装',
    exchange_items: '钻石、彩虹晶体、绿宝石、夜焰等离子、等离子裁决套装LV5'
  },
  {
    name: '双难守护者',
    description: '这是一个负责交易的守护者，主要职责是提供基本的交易服务，可能涉及到一些特定的物品和资源的交易.',
    dropItems: '隐匿烟幕、毒气精华、雷电精华',
    exchange_items: '钻石、彩虹晶体、绿宝石、毒气精华、雷电精华、双难鳞片、双难守护套装'
  },
  {
    name: '双难守护者-长官',
    description: '这是双难守护者的高级版本，拥有更多的交易选项，可能会提供一些稀有物品和高级资源。长官级NPC通常还承担着更多的管理和指导职责.',
    dropItems: '隐匿烟幕、毒气精华、雷电精华、双难盔甲套装',
    exchange_items: '钻石、彩虹晶体、绿宝石、毒气精华、雷电精华、双难鳞片、双难守护套装LV5'
  },
  {
    name: '葛伦科守护者',
    description: '这是一个负责交易的守护者，主要职责是提供基本的交易服务，可能涉及到一些特定的物品和资源的交易.',
    dropItems: '葛伦科铁块',
    exchange_items: '钻石、彩虹晶体、绿宝石、葛伦科铁块、葛伦科套装'
  },
  {
    name: '葛伦科守护者-长官',
    description: '这是葛伦科守护者的高级版本，拥有更多的交易选项，可能会提供一些稀有物品和高级资源。长官级NPC通常还承担着更多的管理和指导职责.',
    dropItems: '葛伦科铁块、葛伦科套装',
    exchange_items: '钻石、彩虹晶体、绿宝石、葛伦科铁块、葛伦科套装Lv5'
  },
  {
    name: '重型葛伦科守护者',
    description: '这是一个负责交易的守护者，主要职责是提供基本的交易服务，可能涉及到一些特定的物品和资源的交易。由于是重型，可能意味着这个NPC可以提供一些更大宗或更重型的物资交易.',
    dropItems: '重质鳞片',
    exchange_items: '钻石、彩虹晶体、绿宝石、重质鳞片、重形葛伦科套装'
  },
  {
    name: '重型葛伦科守护者-长官',
    description: '这是重型葛伦科守护者的高级版本，拥有更多的交易选项，可能会提供一些稀有物品和高级资源。长官级NPC通常还承担着更多的管理和指导职责。由于是重型，可能意味着这个NPC可以提供一些更大宗或更重型的物资交易.',
    dropItems: '重质鳞片、重形葛伦科套装',
    exchange_items: '钻石、彩虹晶体、绿宝石、重质鳞片、重形葛伦科套装Lv5'
  },
];
