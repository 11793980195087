// src/config.js
export const weapons = [
      {
          "name": "等离子裁决之剑-常见",
          "identifier": "plasma_judgment_armor_weapon_0",
          "max_damage": 304,
          "attack_damage": 10,
          "enchantment": 15,
          "AttackSpeedAmplifier": 0,
          "AttackInterval": 0.5,
          "AttackSecondCount": 2,
          "DPS": 20
      },
      {
          "name": "等离子裁决之剑-精英",
          "identifier": "plasma_jjudgment_armor_weapon_1",
          "max_damage": 608,
          "attack_damage": 11,
          "enchantment": 16,
          "AttackSpeedAmplifier": 0.13,
          "AttackInterval": 0.435,
          "AttackSecondCount": 2.298850575,
          "DPS": 25.28735632
      },
      {
          "name": "等离子裁决之剑-优质",
          "identifier": "plasma_judgment_armor_weapon_2",
          "max_damage": 1216,
          "attack_damage": 12,
          "enchantment": 17,
          "AttackSpeedAmplifier": 0.16,
          "AttackInterval": 0.42,
          "AttackSecondCount": 2.380952381,
          "DPS": 28.57142857
      },
      {
          "name": "等离子裁决之剑-罕见",
          "identifier": "plasma_judgment_armor_weapon_3",
          "max_damage": 2433,
          "attack_damage": 13,
          "enchantment": 18,
          "AttackSpeedAmplifier": 0.19,
          "AttackInterval": 0.405,
          "AttackSecondCount": 2.469135802,
          "DPS": 32.09876543
      },
      {
          "name": "等离子裁决之剑-大师",
          "identifier": "plasma_judgment_armor_weapon_4",
          "max_damage": 4866,
          "attack_damage": 14,
          "enchantment": 20,
          "AttackSpeedAmplifier": 0.25,
          "AttackInterval": 0.375,
          "AttackSecondCount": 2.666666667,
          "DPS": 37.33333333
      },
      {
          "name": "等离子裁决之剑-传奇",
          "identifier": "plasma_judgment_armor_weapon_5",
          "max_damage": 9732,
          "attack_damage": 16,
          "enchantment": 22,
          "AttackSpeedAmplifier": 0.3,
          "AttackInterval": 0.35,
          "AttackSecondCount": 2.857142857,
          "DPS": 45.71428571
      },
      {
          "name": "纳德尖刺短剑-常见",
          "identifier": "nadder_spiked_dagger_0",
          "max_damage": 304,
          "attack_damage": 10,
          "enchantment": 7,
          "AttackSpeedAmplifier": 0.2,
          "AttackInterval": 0.4,
          "AttackSecondCount": 2.5,
          "DPS": 25
      },
      {
          "name": "纳德尖刺短剑-精英",
          "identifier": "nadder_spiked_dagger_1",
          "max_damage": 608,
          "attack_damage": 11,
          "enchantment": 8,
          "AttackSpeedAmplifier": 0.23,
          "AttackInterval": 0.385,
          "AttackSecondCount": 2.597402597,
          "DPS": 28.57142857
      },
      {
          "name": "纳德尖刺短剑-优质",
          "identifier": "nadder_spiked_dagger_2",
          "max_damage": 1216,
          "attack_damage": 12,
          "enchantment": 9,
          "AttackSpeedAmplifier": 0.26,
          "AttackInterval": 0.37,
          "AttackSecondCount": 2.702702703,
          "DPS": 32.43243243
      },
      {
          "name": "纳德尖刺短剑-罕见",
          "identifier": "nadder_spiked_dagger_3",
          "max_damage": 2433,
          "attack_damage": 13,
          "enchantment": 11,
          "AttackSpeedAmplifier": 0.3,
          "AttackInterval": 0.35,
          "AttackSecondCount": 2.857142857,
          "DPS": 37.14285714
      },
      {
          "name": "纳德尖刺短剑-大师",
          "identifier": "nadder_spiked_dagger_4",
          "max_damage": 3649,
          "attack_damage": 14,
          "enchantment": 13,
          "AttackSpeedAmplifier": 0.35,
          "AttackInterval": 0.325,
          "AttackSecondCount": 3.076923077,
          "DPS": 43.07692308
      },
      {
          "name": "纳德尖刺短剑-传奇",
          "identifier": "nadder_spiked_dagger_5",
          "max_damage": 4865,
          "attack_damage": 16,
          "enchantment": 15,
          "AttackSpeedAmplifier": 0.4,
          "AttackInterval": 0.3,
          "AttackSecondCount": 3.333333333,
          "DPS": 53.33333333
      },
      {
          "name": "纳德尖刺长矛-常见",
          "identifier": "nadder_spiked_spear_0",
          "max_damage": 304,
          "attack_damage": 14,
          "enchantment": 7,
          "AttackSpeedAmplifier": -0.2,
          "AttackInterval": 0.6,
          "AttackSecondCount": 1.666666667,
          "DPS": 23.33333333
      },
      {
          "name": "纳德尖刺长矛-精英",
          "identifier": "nadder_spiked_spear_1",
          "max_damage": 608,
          "attack_damage": 15,
          "enchantment": 8,
          "AttackSpeedAmplifier": -0.17,
          "AttackInterval": 0.585,
          "AttackSecondCount": 1.709401709,
          "DPS": 25.64102564
      },
      {
          "name": "纳德尖刺长矛-优质",
          "identifier": "nadder_spiked_spear_2",
          "max_damage": 1216,
          "attack_damage": 16,
          "enchantment": 9,
          "AttackSpeedAmplifier": -0.14,
          "AttackInterval": 0.57,
          "AttackSecondCount": 1.754385965,
          "DPS": 28.07017544
      },
      {
          "name": "纳德尖刺长矛-罕见",
          "identifier": "nadder_spiked_spear_3",
          "max_damage": 2433,
          "attack_damage": 17,
          "enchantment": 11,
          "AttackSpeedAmplifier": -0.11,
          "AttackInterval": 0.555,
          "AttackSecondCount": 1.801801802,
          "DPS": 30.63063063
      },
      {
          "name": "纳德尖刺长矛-大师",
          "identifier": "nadder_spiked_spear_4",
          "max_damage": 3649,
          "attack_damage": 18,
          "enchantment": 13,
          "AttackSpeedAmplifier": -0.05,
          "AttackInterval": 0.525,
          "AttackSecondCount": 1.904761905,
          "DPS": 34.28571429
      },
      {
          "name": "纳德尖刺长矛-传奇",
          "identifier": "nadder_spiked_spear_5",
          "max_damage": 4865,
          "attack_damage": 20,
          "enchantment": 15,
          "AttackSpeedAmplifier": 0,
          "AttackInterval": 0.5,
          "AttackSecondCount": 2,
          "DPS": 40
      },
      {
          "name": "毒刃尖刺短剑-常见",
          "identifier": "poison_blade_spiked_dagger_0",
          "max_damage": 304,
          "attack_damage": 3,
          "enchantment": 15,
          "AttackSpeedAmplifier": 0.2,
          "AttackInterval": 0.4,
          "AttackSecondCount": 2.5,
          "DPS": 7.5
      },
      {
          "name": "毒刃尖刺短剑-精英",
          "identifier": "poison_blade_spiked_dagger_1",
          "max_damage": 608,
          "attack_damage": 3,
          "enchantment": 16,
          "AttackSpeedAmplifier": 0.23,
          "AttackInterval": 0.385,
          "AttackSecondCount": 2.597402597,
          "DPS": 7.792207792
      },
      {
          "name": "毒刃尖刺短剑-优质",
          "identifier": "poison_blade_spiked_dagger_2",
          "max_damage": 1216,
          "attack_damage": 4,
          "enchantment": 17,
          "AttackSpeedAmplifier": 0.26,
          "AttackInterval": 0.37,
          "AttackSecondCount": 2.702702703,
          "DPS": 10.81081081
      },
      {
          "name": "毒刃尖刺短剑-罕见",
          "identifier": "poison_blade_spiked_dagger_3",
          "max_damage": 2433,
          "attack_damage": 4,
          "enchantment": 18,
          "AttackSpeedAmplifier": 0.3,
          "AttackInterval": 0.35,
          "AttackSecondCount": 2.857142857,
          "DPS": 11.42857143
      },
      {
          "name": "毒刃尖刺短剑-大师",
          "identifier": "poison_blade_spiked_dagger_4",
          "max_damage": 3649,
          "attack_damage": 5,
          "enchantment": 20,
          "AttackSpeedAmplifier": 0.35,
          "AttackInterval": 0.325,
          "AttackSecondCount": 3.076923077,
          "DPS": 15.38461538
      },
      {
          "name": "毒刃尖刺短剑-传奇",
          "identifier": "poison_blade_spiked_dagger_5",
          "max_damage": 4865,
          "attack_damage": 6,
          "enchantment": 22,
          "AttackSpeedAmplifier": 0.4,
          "AttackInterval": 0.3,
          "AttackSecondCount": 3.333333333,
          "DPS": 20
      },
      {
          "name": "毒刃尖刺长矛-常见",
          "identifier": "poison_blade_spiked_spear_0",
          "max_damage": 304,
          "attack_damage": 6,
          "enchantment": 15,
          "AttackSpeedAmplifier": -0.2,
          "AttackInterval": 0.6,
          "AttackSecondCount": 1.666666667,
          "DPS": 10
      },
      {
          "name": "毒刃尖刺长矛-精英",
          "identifier": "poison_blade_spiked_spear_1",
          "max_damage": 608,
          "attack_damage": 7,
          "enchantment": 16,
          "AttackSpeedAmplifier": -0.17,
          "AttackInterval": 0.585,
          "AttackSecondCount": 1.709401709,
          "DPS": 11.96581197
      },
      {
          "name": "毒刃尖刺长矛-优质",
          "identifier": "poison_blade_spiked_spear_2",
          "max_damage": 1216,
          "attack_damage": 8,
          "enchantment": 17,
          "AttackSpeedAmplifier": -0.14,
          "AttackInterval": 0.57,
          "AttackSecondCount": 1.754385965,
          "DPS": 14.03508772
      },
      {
          "name": "毒刃尖刺长矛-罕见",
          "identifier": "poison_blade_spiked_spear_3",
          "max_damage": 2433,
          "attack_damage": 9,
          "enchantment": 18,
          "AttackSpeedAmplifier": -0.11,
          "AttackInterval": 0.555,
          "AttackSecondCount": 1.801801802,
          "DPS": 16.21621622
      },
      {
          "name": "毒刃尖刺长矛-大师",
          "identifier": "poison_blade_spiked_spear_4",
          "max_damage": 3649,
          "attack_damage": 10,
          "enchantment": 20,
          "AttackSpeedAmplifier": -0.05,
          "AttackInterval": 0.525,
          "AttackSecondCount": 1.904761905,
          "DPS": 19.04761905
      },
      {
          "name": "毒刃尖刺长矛-传奇",
          "identifier": "poison_blade_spiked_spear_5",
          "max_damage": 4865,
          "attack_damage": 12,
          "enchantment": 22,
          "AttackSpeedAmplifier": 0,
          "AttackInterval": 0.5,
          "AttackSecondCount": 2,
          "DPS": 24
      },
      {
          "name": "噩梦裁决之剑-常见",
          "identifier": "sword_of_nightmare_judgment_0",
          "max_damage": 304,
          "attack_damage": 10,
          "enchantment": 15,
          "AttackSpeedAmplifier": 0,
          "AttackInterval": 0.5,
          "AttackSecondCount": 2,
          "DPS": 20
      },
      {
          "name": "噩梦裁决之剑-精英",
          "identifier": "sword_of_nightmare_judgment_1",
          "max_damage": 608,
          "attack_damage": 11,
          "enchantment": 16,
          "AttackSpeedAmplifier": 0.06,
          "AttackInterval": 0.47,
          "AttackSecondCount": 2.127659574,
          "DPS": 23.40425532
      },
      {
          "name": "噩梦裁决之剑-优质",
          "identifier": "sword_of_nightmare_judgment_2",
          "max_damage": 1216,
          "attack_damage": 12,
          "enchantment": 17,
          "AttackSpeedAmplifier": 0.07,
          "AttackInterval": 0.465,
          "AttackSecondCount": 2.150537634,
          "DPS": 25.80645161
      },
      {
          "name": "噩梦裁决之剑-罕见",
          "identifier": "sword_of_nightmare_judgment_3",
          "max_damage": 2433,
          "attack_damage": 13,
          "enchantment": 18,
          "AttackSpeedAmplifier": 0.1,
          "AttackInterval": 0.45,
          "AttackSecondCount": 2.222222222,
          "DPS": 28.88888889
      },
      {
          "name": "噩梦裁决之剑-大师",
          "identifier": "sword_of_nightmare_judgment_4",
          "max_damage": 4866,
          "attack_damage": 14,
          "enchantment": 20,
          "AttackSpeedAmplifier": 0.12,
          "AttackInterval": 0.44,
          "AttackSecondCount": 2.272727273,
          "DPS": 31.81818182
      },
      {
          "name": "噩梦裁决之剑-传奇",
          "identifier": "sword_of_nightmare_judgment_5",
          "max_damage": 9732,
          "attack_damage": 16,
          "enchantment": 22,
          "AttackSpeedAmplifier": 0.17,
          "AttackInterval": 0.415,
          "AttackSecondCount": 2.409638554,
          "DPS": 38.55421687
      },
      {
          "name": "黑暗之焰剑-常见",
          "identifier": "dark_flame_sword_0",
          "max_damage": 304,
          "attack_damage": 10,
          "enchantment": 15,
          "AttackSpeedAmplifier": 0,
          "AttackInterval": 0.5,
          "AttackSecondCount": 2,
          "DPS": 20
      },
      {
          "name": "黑暗之焰剑-精英",
          "identifier": "dark_flame_sword_1",
          "max_damage": 608,
          "attack_damage": 11,
          "enchantment": 16,
          "AttackSpeedAmplifier": 0.06,
          "AttackInterval": 0.47,
          "AttackSecondCount": 2.127659574,
          "DPS": 23.40425532
      },
      {
          "name": "黑暗之焰剑-优质",
          "identifier": "dark_flame_sword_2",
          "max_damage": 1216,
          "attack_damage": 12,
          "enchantment": 17,
          "AttackSpeedAmplifier": 0.07,
          "AttackInterval": 0.465,
          "AttackSecondCount": 2.150537634,
          "DPS": 25.80645161
      },
      {
          "name": "黑暗之焰剑-罕见",
          "identifier": "dark_flame_sword_3",
          "max_damage": 2433,
          "attack_damage": 13,
          "enchantment": 18,
          "AttackSpeedAmplifier": 0.1,
          "AttackInterval": 0.45,
          "AttackSecondCount": 2.222222222,
          "DPS": 28.88888889
      },
      {
          "name": "黑暗之焰剑-大师",
          "identifier": "dark_flame_sword_4",
          "max_damage": 4866,
          "attack_damage": 14,
          "enchantment": 20,
          "AttackSpeedAmplifier": 0.12,
          "AttackInterval": 0.44,
          "AttackSecondCount": 2.272727273,
          "DPS": 31.81818182
      },
      {
          "name": "黑暗之焰剑-传奇",
          "identifier": "dark_flame_sword_5",
          "max_damage": 9732,
          "attack_damage": 16,
          "enchantment": 22,
          "AttackSpeedAmplifier": 0.17,
          "AttackInterval": 0.415,
          "AttackSecondCount": 2.409638554,
          "DPS": 38.55421687
      }
  ];