// src/config.js
export const blocks = [
    {
        "name": "暗之晶体矿石",
        "identifier": "dark_crystal",
        "description": "能够挖掘出暗之晶体,有概率挖出彩虹晶体",
        "spwan_location": "下界荒地、末地、黑森林、恶地、繁茂的恶地高原、恶地高原、黑森林丘陵、恶地高原变种、灵魂沙峡谷、诡异森林、深暗之域"
    },
    {
        "name": "光之晶体矿石",
        "identifier": "light_crystal",
        "description": "能够挖掘出光之晶体,有概率挖出彩虹晶体",
        "spwan_location": "平原、山地、森林、繁茂的丘陵、桦木森林、桦木森林丘陵、黑森林、繁花森林、高大桦木森林、高大桦木丘陵、黑森林丘陵、樱花树林."
    },
    {
        "name": "魔法晶体矿石",
        "identifier": "magic_crystal",
        "description": "能够挖掘出魔法晶体,有概率挖出彩虹晶体",
        "spwan_location": "蘑菇岛、蘑菇岛岸、积雪的沙滩、冻洋、竹林、竹林丘陵、向日葵平原、黑森林丘陵、积雪的针叶林山地、巨型云杉针叶林、巨型云杉针叶林丘陵、沙砾山地、破碎的热带草原、破碎的热带高原、被风蚀的恶地、繁茂的恶地高原变种、恶地高原变种、绯红森林、繁茂洞穴、溶洞、深暗之域、樱花树林."
    },
    {
        "name": "红死神祭坛",
        "identifier": "red_death",
        "description": "向内丢弃16*彩虹晶体、32*暗之晶体、32*钻石即可召唤红死神",
        "spwan_location": "山地、尖峭山峰、裸岩山峰、下界荒地、灵魂沙峡谷、蘑菇岛、蘑菇岛岸、深暗之域、玄武岩三角洲、雪山、平原、沙漠"
    }
];